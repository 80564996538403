<template>
	<div>
		<header class="header-sd">
			<div class="content">
				<div class="header-sd__inner">
					<div class="header-sd__left">
						<router-link
							to="/"
							class="logo"
						/>
					</div>
					<div class="header-sd__right">
						<div
							class="navigation-sd"
							:class="{'navigation-sd--active': isOpenMenu}"
						>
							<a
								class="navigation__link"
								@click="$scrollTo($refs.queue)"
							>Порядок<br> участия</a>
							<a
								class="navigation__link"
								@click="$scrollTo($refs.lead)"
							>Ведущие<br> и призы</a>
							<a
								class="navigation__link"
								@click="$scrollTo($refs.tasks)"
							>Задачи от лидеров<br> профессий</a>
							<a
								class="navigation__link"
								@click="$scrollTo($refs.partners)"
							>Партнеры</a>
							<a
								class="navigation__link"
								@click="modalOpen('sign-in-ur')"
							>Регистрация</a>
						</div>
						<div
							class="burger-sd"
							:class="{ 'burger-sd--active': isOpenMenu}"
							@click="isOpenMenu = !isOpenMenu"
						/>
					</div>
				</div>
			</div>
		</header>
		<div class="sections">
			<section class="banner">
				<div class="content">
					<div class="banner__inner">
						<h1 class="banner__title">
							Всероссийский урок <br>по устойчивому развитию
						</h1>
						<img
							:src="'/img/logo-white-x2-mobile.png' | prodSrcImage"
							alt="Лифт в будущее"
							class="banner__logo"
						>
						<div class="banner__content">
							<div class="banner__box">
								<div class="banner__text">
									Образовательный проект <b>БФ «Система»</b>, который помогает школьникам осознанно подойти к выбору профессии: информирует о карьерных треках и дает возможность получить необходимые навыки с помощью курсов на образовательной платформе «Лифт в будущее».
								</div>
								<div class="banner__sponsor">
									Об организаторе: благотворительный фонд «Система» - партнер нацпроекта «Образование». Проекты Фонда включены в число лучших практик Устойчивого развития ООН.
								</div>
							</div>
							<div class="banner__box">
								<button
									class="button-sd"
									@click="modalOpen('sign-in-ur')"
								>
									регистрация
								</button>
								<div class="banner-speech">
									<div class="banner-speech__time">
										урок прошел
									</div>
									<div class="banner-speech__date">
										19 мая
									</div>
									<button
										class="button-sd button-sd--white button-sd--sm"
										@click="$scrollTo($refs.translation)"
									>
										ПОСМОТРЕТЬ
									</button>
								</div>
							</div>
						</div>
						<img
							:src="'/img/landing/lesson-sd/banner__pic.svg' | prodSrcImage"
							alt="Картинка"
							class="banner__pic"
						>
						<div class="banner__design-1" />
						<div class="banner__design-2" />
						<div class="banner__design-3" />
						<div class="banner__design-4" />
					</div>
				</div>
			</section>

			<section class="audience">
				<div class="content">
					<div class="audience__part">
						<div class="audience__title audience__title--design-1">
							Для кого?
						</div>
						<div class="audience__content">
							<div class="audience__list">
								<div class="audience__item audience__item--selected">
									Для учеников 7- 11 классов
								</div>
								<div class="audience__item">
									Для педагогов и родителей
								</div>
							</div>
						</div>
					</div>
					<div class="audience__part">
						<div class="audience__title audience__title--design-2">
							Для чего?
						</div>
						<div class="audience__content">
							<div class="audience__list">
								<div class="audience__item">
									Узнать об основополагающей концепции развития современной экономики
								</div>
								<div class="audience__item">
									Освоить востребованные знания и умения
								</div>
								<div class="audience__item">
									Внести вклад в проекты по устойчивому развитию в своем регионе
								</div>
							</div>
						</div>
					</div>
					<div class="audience__design-1" />
					<div class="audience__design-2" />
					<div class="audience__design-3" />
				</div>
			</section>

			<section
				ref="queue"
				class="steps"
			>
				<div class="content">
					<h2 class="title-section">
						Открытый урок проходил в два этапа
					</h2>
					<div class="steps__list">
						<div class="steps__info">
							<div class="steps__title steps__title--1">
								1 этап
							</div>
							<div class="steps__date steps__date--1">
								18 апреля - 13 мая
							</div>
						</div>
						<div class="step step--1">
							<div class="step__index">
								1
							</div>
							<div class="step__title">
								Регистрируйтесь на платформе <router-link
									to="/"
									target="_blank"
								>
									«Лифт в будущее»
								</router-link>
							</div>
						</div>
						<div class="step step--2">
							<div class="step__index">
								2
							</div>
							<div class="step__title">
								Изучайте материалы по экологии и устойчивому развитию
							</div>
						</div>
						<div class="step step--3">
							<div class="step__index">
								3
							</div>
							<div class="step__title">
								Пройдите курс по этим темам
							</div>
						</div>
						<div class="step step--4">
							<div class="step__index">
								4
							</div>
							<div class="step__title">
								Выполняйте задания и выигрывайте призы
							</div>
						</div>
						<div class="steps__info">
							<div class="steps__title steps__title--2">
								2 этап
							</div>
							<div class="steps__date steps__date--2">
								19 мая
							</div>
						</div>
						<div class="step step--5">
							<div class="step__index">
								5
							</div>
							<div class="step__title">
								Онлайн-трансляция <b>Урока с экспертами</b> и объявление победителей конкурса, а также индивидуальные испытания с <b>главными призами мероприятия</b>
							</div>
						</div>
					</div>
					<div class="steps__design-1" />
					<div class="steps__design-2" />
				</div>
			</section>

			<section
				ref="lead"
				class="speakers"
			>
				<div class="content">
					<h2 class="title-section">
						Ведущие трансляции
					</h2>
					<div class="speakers__list">
						<div class="speaker">
							<img
								:src="'/img/landing/lesson-sd/pic__speaker-5--x2.png' | prodSrcImage"
								alt="Фото"
								class="speaker__pic"
							>
							<div class="speaker__content">
								<div class="speaker__name">
									Сергей <b>Матвиенко</b>
								</div>
								<div class="speaker__position">
									Юморист, видеоблогер, актер шоу «Импровизация»
								</div>
							</div>
						</div>
						<div class="speaker">
							<img
								:src="'/img/landing/lesson-sd/pic__speaker-6--x2.png' | prodSrcImage"
								alt="Фото"
								class="speaker__pic"
							>
							<div class="speaker__content">
								<div class="speaker__name">
									Саша <b>Монтаг</b>
								</div>
								<div class="speaker__position">
									Видеоблогер и музыкант, автор сольного проекта FOILAR
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="prizes">
				<div class="content">
					<h3 class="prizes__pretitle">
						3 победителя
					</h3>
					<h3 class="prizes__title">
						получили ценные призы
					</h3>
					<div class="prizes__list">
						<div class="prize">
							<img
								:src="'/img/landing/lesson-sd/pic__prize-1--x2.png' | prodSrcImage"
								width="47"
								height="67"
								alt="Изображение"
								class="prize__pic"
							>
							<div class="prize__title">
								Планшет Apple iPad 10.2
							</div>
						</div>
						<div class="prize">
							<img
								:src="'/img/landing/lesson-sd/pic__prize-2--x2.png' | prodSrcImage"
								width="125"
								height="60"
								alt="Изображение"
								class="prize__pic"
							>
							<div class="prize__title">
								Портативная колонка JBL
							</div>
						</div>
						<div class="prize">
							<img
								:src="'/img/landing/lesson-sd/pic__prize-3--x2.png' | prodSrcImage"
								width="95"
								height="84"
								alt="Изображение"
								class="prize__pic"
							>
							<div class="prize__title">
								Сертификат OZON на 10 000₽
							</div>
						</div>
					</div>
					<div class="prizes__design-1" />
					<div class="prizes__design-2" />
					<div class="prizes__design-3" />
				</div>
			</section>

			<section class="professions">
				<div class="content">
					<div class="professions__inner">
						<div class="professions__content">
							<h2 class="title-section">
								Кейсы компаний
							</h2>
							<div class="professions__list">
								<div class="profession profession--1">
									<div class="profession__index">
										1
									</div>
									<div class="profession__title">
										Сбор и подготовка упаковки для вторичной переработки, Ozon
									</div>
								</div>
								<div class="profession profession--2">
									<div class="profession__index">
										2
									</div>
									<div class="profession__title">
										Корпоративная культура как фундамент роста молодого сотрудника, МТС
									</div>
								</div>
								<div class="profession profession--3">
									<div class="profession__index">
										3
									</div>
									<div class="profession__title">
										Квадрокоптеры, автопилоты и новые сорта растений - настоящее сельского хозяйства, Агрохолдинг Степь
									</div>
								</div>
								<div class="profession profession--4">
									<div class="profession__index">
										4
									</div>
									<div class="profession__title">
										Привлечение и удержание талантов, ВК
									</div>
								</div>
								<div class="profession profession--5">
									<div class="profession__index">
										5
									</div>
									<div class="profession__title">
										Производство биотоплива и переработка отходов лесозаготовки, Segezha Group
									</div>
								</div>
								<div class="profession profession--6">
									<div class="profession__index">
										6
									</div>
									<div class="profession__title">
										Инженерные компетенции - основа экономики будущего, Росатом
									</div>
								</div>
								<div class="profession profession--7">
									<div class="profession__index">
										7
									</div>
									<div class="profession__title">
										Молодежное предпринимательство родным регионам, Северсталь
									</div>
								</div>
							</div>
						</div>
						<div class="professions__design-1" />
						<div class="professions__design-2" />
					</div>
				</div>
			</section>

			<section
				ref="tasks"
				class="leaders"
			>
				<div class="content">
					<h2 class="title-section">
						Задачи от лидеров
					</h2>
					<div class="leaders__text">
						Как выбрать специальность и какие навыки помогут быть успешным в карьере? В рамках Всероссийского урока известные представители профессий расскажут об устойчивом развитии и перспективных компетенциях - а Лифт в будущее позволит овладеть этими умениями и применить их на практике
					</div>
					<div class="leaders__list">
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_2.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Фетисова Оксана
								</div>
							</div>
							<div class="leader__position">
								Директор Корпоративного университета МТС
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_1.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Сорокина Светлана
								</div>
							</div>
							<div class="leader__position">
								Руководитель проектов устойчивого развития Ozon
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_3.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Топоркова Ксения
								</div>
							</div>
							<div class="leader__position">
								Менеджер по Устойчивому Развитию, VK
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_4.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Ткаченко Сергей
								</div>
							</div>
							<div class="leader__position">
								Заместитель генерального директора – Директор R&D центра АО Агрохолдинг «СТЕПЬ»
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_5.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Поярков  Дмитрий
								</div>
							</div>
							<div class="leader__position">
								Начальник управления по КСО и бренду Северсталь
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_6.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Мутьев Вячеслав
								</div>
							</div>
							<div class="leader__position">
								Директор по экологии Segezha Group
							</div>
						</div>
						<div class="leader">
							<div class="leader__pic">
								<img
									:src="'/img/landing/lesson-sd/avatar_lead_7.png' | prodSrcImage"
									alt="Фото"
									class="leader__avatar"
								>
								<div class="leader__name">
									Биккулова Гульнара
								</div>
							</div>
							<div class="leader__position">
								Заместитель генерального директора Академии Росатома — Директор Блока международных инициатив и партнерств
							</div>
						</div>
					</div>
					<div class="leaders__design-1" />
				</div>
			</section>

			<section
				id="translation"
				ref="translation"
				class="translation"
			>
				<div
					class="content"
				>
					<h2 class="title-section">
						Трансляция мероприятия
					</h2>
					<div class="translation__item">
						<video
							src="https://553901.selcdn.ru/lift-bf/STREAM%20CUT_1.mp4"
							style=" border-radius: 10px; border: 1px solid black;"
							class="translation__item"
							playsinline
							controls
						/>
					</div>
				</div>
			</section>

			<section
				ref="partners"
				class="partners-sd"
			>
				<div class="content">
					<h2 class="title-section">
						Информационные партнеры
					</h2>
					<div class="slider-wrap">
						<VueSlickCarousel
							class="partners-sd__slider"
							v-bind="carouselPartnerSettings"
						>
							<div
								v-for="(image, index) in partnerSlider"
								:key="index"
							>
								<div class="partners-sd__partner">
									<img
										:src="image | prodSrcImage"
										alt="Логотип"
										class="partners-sd__logo"
									>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</section>
		</div>
		<footer class="footer-sd">
			<div class="content">
				<div class="footer-sd__top">
					<div class="footer-sd__company">
						<a
							href="https://vk.com/lift2future"
							target="_blank"
							class="footer-sd__social"
						>
							<img
								:src="'/img/landing/lesson-sd/icon__social-vk.svg' | prodSrcImage"
								alt="ВК"
							>
						</a>
						<div class="logo" />
					</div>
					<div class="footer-sd__links">
						<a
							:href="'/media/press_reliz.pdf' | prodSrcImage"
							target="_blank"
							class="link-sd"
						><span>Пресс-релиз</span></a>
						<a
							:href="'/media/konkurs_for_school.pdf' | prodSrcImage"
							target="_blank"
							class="link-sd"
						><span>Положение о проведении конкурса</span></a>
						<a
							:href="'/media/participant-cert.pdf' | prodSrcImage"
							target="_blank"
							class="link-sd"
						><span>Сертификат участника</span></a>
					</div>
				</div>
				<div class="footer-sd__bottom">
					<a
						href="http://bf.sistema.ru/"
						target="_blank"
						class="link-sd"
					><span>2023 © Благотворительный фонд «Система»</span></a>
					<router-link
						to="/privacy-policy"
						class="link-sd"
					>
						<span>Политика конфиденциальности</span>
					</router-link>
					<router-link
						to="/agreement"
						class="link-sd"
					>
						<span>Пользовательское соглашение</span>
					</router-link>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>

export default {
    name: 'LandingUr',
    data: () => ({
        countdown: {
            days: 0,
            hours: 0,
            minutes: 0
        },
        isOpenMenu: false,
        partnerSlider: [
            '/img/landing/lesson-sd/logo__partner-1.png',
            '/img/landing/lesson-sd/logo__partner-2.png',
            '/img/landing/lesson-sd/logo__partner-3.png',
            '/img/landing/lesson-sd/logo__partner-4.png',
            '/img/landing/lesson-sd/logo__partner-5.png',
            '/img/landing/lesson-sd/logo__partner-6.png',
            '/img/landing/lesson-sd/logo__partner-7.png',
            '/img/landing/lesson-sd/logo__partner-8.png',
            '/img/landing/lesson-sd/logo__partner-9.png',
            '/img/landing/lesson-sd/logo__partner-10.png',
            '/img/landing/lesson-sd/logo__partner-11.png',
            '/img/landing/lesson-sd/logo__partner-12.png',
            '/img/landing/lesson-sd/logo__partner-13.png',
            '/img/landing/lesson-sd/logo__partner-14.png',
            '/img/landing/lesson-sd/logo__partner-15.png',
            '/img/landing/lesson-sd/logo__partner-16.svg'

        ],
        carouselPartnerSettings: {
            arrows: true,
            dots: false,
            infinite: true,
            'slides-to-show': 5,
            'slides-to-scroll': 2,
            draggable: true,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1299,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    mounted() {
        if (this.$route.query.translation) this.$scrollTo(document.querySelector('footer.footer-sd'))
    },
    methods: {
        getTime () {
            let now = new Date();
            let checkPointDate = new Date(
                now.getFullYear(),
                4,
                19,
                10,
                0
            );

            let diff = Math.ceil((checkPointDate - now) / 1000);

            this.countdown.days = Math.floor(diff / (60 * 60 * 24));
            diff = diff % (60 * 60 * 24);

            this.countdown.hours = Math.floor(diff / (60 * 60));
            diff = diff % (60 * 60);

            this.countdown.minutes = Math.floor(diff / 60);
            diff = diff % 60;
        },
        toPlural (value, one, two, five) {
            let n = Math.abs(value);
            n %= 100;
            if (n >= 5 && n <= 20) {
                return `${five}`;
            }
            n %= 10;
            if (n === 1) {
                return `${one}`;
            }
            if (n >= 2 && n <= 4) {
                return `${two}`;
            }
            return `${five}`;
        },
    }
}
</script>

<style lang="less">
@import "../../assets/styles/pages/landing__lesson-sd";
.lesson-sd .sections {
    overflow-x: hidden;
}
.partners-sd__logo {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
</style>
